import axios from "axios";

export const AUTH_API_URL =
  window.location.hostname === "localhost"
    ? "https://test.shinhanx.kz/api-auth"
    : window.location.protocol + "//" + window.location.host + "/api-auth";

export const NOTIFICATION_API_URL =
  window.location.hostname === "localhost"
    ? "https://test.shinhanx.kz/api-notification"
    : window.location.protocol +
      "//" +
      window.location.host +
      "/api-notification";
export const MISC_API_URL =
  window.location.hostname === "localhost"
    ? "https://test.shinhanx.kz/api-misc"
    : window.location.protocol + "//" + window.location.host + "/api-misc";
export const ML_API_URL = "https://shinhanx.kz/api-ml";

export async function getUserRoleByToken(token) {
  return await axios.get(AUTH_API_URL + "/users/getUserByToken", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function subscribeToNotifications(token, fcm_token) {
  return await axios.post(
    NOTIFICATION_API_URL + "/notification/addToken?to_token=" + fcm_token,
    {},
    { headers: { Authorization: `Bearer ${token}` } },
  );
}

export function getModuleUrl(module) {
  if (window.location.hostname.includes("test")) {
    return "https://test-" + module + ".shinhanx.kz";
  } else if (window.location.hostname.includes("dev")) {
    return "https://dev-" + module + ".shinhanx.kz";
  } else {
    return "https://" + module + ".shinhanx.kz";
  }
}

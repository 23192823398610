import React, { Component } from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  Badge,
  ListItemText,
  Snackbar,
} from "@material-ui/core";
import CarIcon from "@material-ui/icons/DriveEta";
import CarOutlinedIcon from "@material-ui/icons/DriveEtaOutlined";
import DollarIcon from "@material-ui/icons/MonetizationOn";
import TagIcon from "@material-ui/icons/LocalOffer";
import FilterIcon from "@material-ui/icons/FilterList";
import ParserIcon from "@material-ui/icons/BugReport";
import UploadIcon from "@material-ui/icons/CloudUpload";
import PrintIcon from "@material-ui/icons/Print";
import UserIcon from "@material-ui/icons/AccountCircle";
import CarWashIcon from "@material-ui/icons/LocalCarWash";
import CalendarIcon from "@material-ui/icons/DateRange";
import { withRouter } from "react-router-dom";
import ReportIcon from "@material-ui/icons/ListAlt";
import LikeIcon from "@material-ui/icons/ThumbUpAlt";
import ClientIcon from "@material-ui/icons/Face";
import ExploreIcon from "@material-ui/icons/Explore";
import PinIcon from "@material-ui/icons/PersonPin";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import KeyIcon from "@material-ui/icons/VpnKey";
import VeditorIcon from "@material-ui/icons/BorderColor";
import DashboardIcon from "@material-ui/icons/BarChart";
import CCIcon from "@material-ui/icons/ContactPhone";
import DocsIcon from "@material-ui/icons/MenuBook";
import XXXIcon from "@material-ui/icons/Beenhere";
import BIIcon from "@material-ui/icons/TrendingUp";
import ContractsIcon from "@material-ui/icons/AllInbox";
import SeoIcon from "@material-ui/icons/EmojiPeople";
import SadminIcon from "@material-ui/icons/ImportantDevices";
import WikiIcon from "@material-ui/icons/ImportContacts";
import ExtensionIcon from "@material-ui/icons/Extension";
import LoyaltyIcon from "@material-ui/icons/Loyalty";
import PeopleIcon from "@material-ui/icons/People";
import HelpIcon from "@material-ui/icons/Help";
import AirportShuttleIcon from "@material-ui/icons/AirportShuttle";
import ScheduleIcon from "@material-ui/icons/Schedule";
import SecurityIcon from "@material-ui/icons/Security";
import QrCode2Icon from "@material-ui/icons/CropFree";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import AllInclusiveIcon from "@material-ui/icons/AllInclusive";
import BuildIcon from "@material-ui/icons/Build";
import PhoneCallbackIcon from "@material-ui/icons/PhoneCallback";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutlined";
import FavoriteIcon from "@material-ui/icons/Favorite";
import PaymentIcon from "@material-ui/icons/Payment";
import BallotIcon from "@material-ui/icons/Ballot";
import AcUnitIcon from "@material-ui/icons/AcUnit";
import ShopIcon from "@material-ui/icons/Shop";
import LanguageIcon from "@material-ui/icons/Language";
import SearchIcon from "@material-ui/icons/Search";
import DeviceUnknownIcon from "@material-ui/icons/DeviceUnknown";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import AlbumIcon from "@material-ui/icons/Album";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MuiAlert from "@material-ui/lab/Alert";
import { parseJwt } from "../lib/util";
import axios from "axios";
import { AUTH_API_URL, MISC_API_URL, ML_API_URL } from "../lib/env";
import { CastForEducation, Money, School } from "@material-ui/icons";
import MailIcon from "@material-ui/icons/Mail";
import DescriptionIcon from "@material-ui/icons/Description";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import AddPhotoIcon from "@material-ui/icons/AddAPhoto";
import HeadpsetIcon from "@material-ui/icons/Headset";

class UserMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isReportsOpen: false,
      BImenuAnchor: null,
      user: null,
      dashboards: [],
      unreadMessages: 0,
    };
  }

  compare(a, b) {
    if (a.project.name < b.project.name) {
      return -1;
    }
    if (a.project.name > b.project.name) {
      return 1;
    }
    return 0;
  }

  getUnreadMessages() {
    let self = this;
    axios
      .get(MISC_API_URL + "/support/countActiveChats", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then(function (response) {
        self.setState({ unreadMessages: response.data });
      })
      .catch(function (error) {
        console.log(error.message);
        if (error.message.includes("401") || error.message.includes("403")) {
          self.props.history.push("/signin");
        }
      });
  }

  componentDidMount() {
    if (!localStorage.getItem("token")) {
      this.props.history.push("/signin");
    }
    let user = parseJwt(localStorage.getItem("token"));
    this.setState({ user });
    console.log(user);
    this.getUnreadMessages();
  }

  render() {
    let menus = [];
    let roles = this.props.user.roles;
    roles.sort(this.compare);

    for (let role of this.props.user.roles) {
      switch (role.project.code) {
        case "FB":
          menus.push(
            <ListItem
              button
              key={role.id}
              onClick={() => {
                this.props.history.push("/carfin");
                this.props.hideDrawer();
              }}
            >
              <ListItemIcon style={{ marginLeft: "7px" }}>
                <DollarIcon />
              </ListItemIcon>
              <ListItemText primary={role.project.name} />
            </ListItem>,
          );
          break;
        case "SUPPORT_ADMIN":
          if (role.code === "ADMIN_HD" || role.code === "ADMIN") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/support");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <FavoriteIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>,
            );
          }
          break;
        case "COLLECTION":
          if (
            role.code === "MANAGER" ||
            role.code === "LAWYER" ||
            role.code === "ADMIN"
          ) {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/collection");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <CalendarIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>,
            );
          }
          break;
        case "CREDIT_FLOW":
          if (
            role.code === "MANAGER" ||
            role.code === "UNDERWRITER" ||
            role.code === "ACCOUNTANT" ||
            role.code === "HEAD_UNDERWRITER" ||
            role.code === "SUPER_UNDERWRITER" ||
            role.code === "CREDIT_ADMIN"
          ) {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/credit-flow");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <Money />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>,
            );
          }
          break;
        case "CAL":
          if (
            role.code === "USER" ||
            role.code === "PURCHASER" ||
            role.code === "HEAD_SALES" ||
            role.code === "HEAD_SALES_CS" ||
            role.code === "TECHNIC" ||
            role.code === "MANAGER_SALES"
          ) {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/calendar");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <CalendarIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>,
            );
          }
          break;
        case "WP":
          if (role.code === "USER") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/welcome");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <ExploreIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>,
            );
          }
          break;

        case "CL":
          if (role.code === "ADMIN") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/clients");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <ClientIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>,
            );
          }
          break;
        case "EDOCS":
          if (role.code === "CREDIT_ADMIN") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/sign-docs");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <ClientIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>,
            );
          }
          break;

        case "UA":
          if (role.code === "ADMIN") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/user_admin");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <UserIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>,
            );
          }
          break;

        case "DOCS":
          if (role.code === "VIEWER" || role.code === "ADMIN") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/docs");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <DocsIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>,
            );
          }
          break;

        case "XXX_CUSTOMER_SUPPORT":
          if (role.code === "SUPER_MODERATOR" || role.code === "MODERATOR") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/customer-support");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <DeviceUnknownIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>,
            );
          }
          break;

        case "WIKI":
          if (
            role.code === "ADMIN" ||
            role.code === "PHOTOGRAPH" ||
            role.code === "MODERATOR" ||
            role.code === "MANAGER_AMP" ||
            role.code === "RGP_AMP" ||
            role.code === "ROP_AMP" ||
            role.code === "BUYER_AMP" ||
            role.code === "RGV_AMP" ||
            role.code === "ROV_AMP" ||
            role.code === "HEAD_AMP" ||
            role.code === "CQ" ||
            role.code === "CQ_SUPER" ||
            role.code === "HEAD_CLIENT_CARE" ||
            role.code === "CX" ||
            role.code === "HEAD_CX" ||
            role.code === "ATZ" ||
            role.code === "MARKETING" ||
            role.code === "HR" ||
            role.code === "TEACHER" ||
            role.code === "ROC_AMP" ||
            role.code === "MOC_AMP" ||
            role.code === "HR" ||
            role.code === "ARARAT" ||
            role.code === "PRICER" ||
            role.code === "STOKER" ||
            role.code === "MOP_OPA" ||
            role.code === "ROP_OPA" ||
            role.code === "HEAD_OPA" ||
            role.code === "MP_AMP" ||
            role.code === "ROS_AMP" ||
            role.code === "PARTS_STOCKER" ||
            role.code === "HEAD_BRANCH"
          ) {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/wiki");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <WikiIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>,
            );
          }
          break;
        case "SADMIN":
          if (role.code === "ADMIN") {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/sadmin");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <SadminIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>,
            );
          }
          break;

        case "QR":
          if (
            role.code === "MANAGER" ||
            role.code === "HEAD" ||
            role.code === "SECURITY"
          ) {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/qr");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <QrCode2Icon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>,
            );
          }
          break;

        case "SECURITY_QR":
          if (
            role.code === "SECURITY" ||
            role.code === "HEAD_BRANCH" ||
            role.code === "ADMIN"
          ) {
            menus.push(
              <ListItem
                button
                key={role.id}
                onClick={() => {
                  this.props.history.push("/security_qr");
                  this.props.hideDrawer();
                }}
              >
                <ListItemIcon style={{ marginLeft: "7px" }}>
                  <VerifiedUserIcon />
                </ListItemIcon>
                <ListItemText primary={role.project.name} />
              </ListItem>,
            );
          }
          break;
        default:
          document.title = "ShinhanX";
          break;
      }
    }

    menus.push(
      <ListItem
        button
        key={"support"}
        onClick={() => {
          this.props.history.push("/support_v2");
          this.props.hideDrawer();
        }}
      >
        <ListItemIcon style={{ marginLeft: "7px" }}>
          <Badge badgeContent={this.state.unreadMessages} color="secondary">
            <HeadpsetIcon />
          </Badge>
        </ListItemIcon>
        <ListItemText primary={"Поддержка"} />
      </ListItem>,
    );

    menus.push(
      <ListItem
        button
        key={"News"}
        onClick={() => {
          this.props.history.push("/news");
          this.props.hideDrawer();
        }}
      >
        <ListItemIcon style={{ marginLeft: "7px" }}>
          <LanguageIcon />
        </ListItemIcon>
        <ListItemText primary={"News"} />
      </ListItem>,
    );

    menus.push(
      <ListItem
        button
        key={"staff-list"}
        onClick={() => {
          this.props.history.push("/staff-list");
          this.props.hideDrawer();
        }}
      >
        <ListItemIcon style={{ marginLeft: "7px" }}>
          <SearchIcon />
        </ListItemIcon>
        <ListItemText primary={"Сотрудники"} />
      </ListItem>,
    );

    menus.push(
      <ListItem
        button
        key={"Mail"}
        onClick={() => {
          window.open("https://webmail.shinhanfinance.kz", "_blank");
          this.props.hideDrawer();
        }}
      >
        <ListItemIcon style={{ marginLeft: "7px" }}>
          <MailIcon />
        </ListItemIcon>
        <ListItemText primary={"Почта"} />
      </ListItem>,
    );

    menus.push(
      <ListItem
        button
        key={"Superset"}
        onClick={() => {
          window.open("https://analytics.shinhanx.kz", "_blank");
          this.props.hideDrawer();
        }}
      >
        <ListItemIcon style={{ marginLeft: "7px" }}>
          <AllInclusiveIcon />
        </ListItemIcon>
        <ListItemText primary={"Аналитика"} />
      </ListItem>,
    );

    function Alert(props) {
      return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    return (
      <div>
        <List>{menus}</List>
      </div>
    );
  }
}

export default withRouter(UserMenu);

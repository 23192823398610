import React, { Component } from "react";

class Home extends Component {
  componentDidMount() {
    if (!this.props.user) {
      return;
    }
    this.props.history.push("/news");
  }

  render() {
    return <div></div>;
  }
}

export default Home;

import React, { Component } from "react";
import "./App.css";
import { Route, Switch } from "react-router-dom";
import {
  AppBar,
  Badge,
  Button,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Snackbar,
  Toolbar,
  Typography,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import Drawer from "@material-ui/core/Drawer";
import { withStyles } from "@material-ui/core/styles";
import * as PropTypes from "prop-types";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import clsx from "clsx";
import logoSvg from "./img/logoSHF.svg";
import UserMenu from "./components/user_menu";
import ModuleContainer from "./pages/module_container";
import {
  AUTH_API_URL,
  getModuleUrl,
  getUserRoleByToken,
  MISC_API_URL,
  subscribeToNotifications,
} from "./lib/env";
import { messaging } from "./init-fcm";
import Home from "./pages/home";
import CloseIcon from "@material-ui/icons/Close";
import axios from "axios";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Profile from "./pages/profile";
import { parseJwt } from "./lib/util";
import moment from "moment";
import "./sass/main.sass";
import CalendarPage from "./pages/calendar";
import Messages from "./pages/messages";

const drawerWidth = 300;
const mobileWidth = 500;

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `100%`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    zIndex: 200,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: window.outerWidth < mobileWidth ? 0 : theme.spacing(9) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
  },
});

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token: localStorage.getItem("token"),
      isDrawerOpen: false,
      user: null,
      documentTitle: "ShinhanX",
      isNotificationSnackbarOpen: false,
      anchorEl: null,
      isOnline: true,
      isNoInternetSnackbarOpen: false,
      isInternetSnackbarOpen: false,
      unreadMessagesCount: 0,
      hbdDialogVisible: false,
    };

    this.finbrokerFrameRef = React.createRef();
    this.authFrameRef = React.createRef();
    this.calFrameRef = React.createRef();
    this.welcomeFrameRef = React.createRef();
    this.docsFrameRef = React.createRef();
    this.xxxBIRef = React.createRef();
    this.wikiFrameRef = React.createRef();
    this.supportFrameRef = React.createRef();
    this.newsFrameRef = React.createRef();
    this.staffListFrameRef = React.createRef();
    this.collectionFrameRef = React.createRef();
    this.renewedSupportFrameRef = React.createRef();
    this.creditFlowFrameRef = React.createRef();
    this.signDocsFrameRef = React.createRef();

    this.hideDrawer = this.hideDrawer.bind(this);
  }

  hideDrawer() {
    this.setState({ isDrawerOpen: false });
  }

  handleIframeTask = (e) => {
    //console.log(e.origin);
    //console.log(e.data);
    // if (e.origin === 'http://localhost:3001') {
    //   alert(e.data)
    // }
  };

  subscribeToNotifications() {
    if (messaging === null) {
      return;
    }

    this.setState({ isNotificationSnackbarOpen: false });

    let self = this;
    getUserRoleByToken(this.state.token)
      .then((res) => {
        self.setState({ user: res.data });

        let isSafari = /^((?!chrome|android).)*safari/i.test(
          navigator.userAgent,
        );
        if (!isSafari) {
          Notification.requestPermission()
            .then((permission) => {
              console.log(permission);
              if (permission === "granted") {
                messaging.getToken().then((fcm_token) => {
                  console.log(fcm_token);
                  localStorage.setItem("fcm_token", fcm_token);
                  subscribeToNotifications(self.state.token, fcm_token)
                    .then((res) => {
                      console.log(res);
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                });
              }
            })
            .catch(function (err) {
              console.log("ERROR: Unable to get permission to notify.");
              console.log(err);
            });
        }

        navigator.serviceWorker.addEventListener("message", (message) => {
          console.log("msg received:");
          console.log(message);
        });
      })
      .catch((err) => {
        self.props.history.push("/signin");
      });
  }

  getUser(token) {
    let self = this;
    axios
      .get(AUTH_API_URL + "/users/getUserByToken", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(function (response) {
        self.setState({ user: response.data });
        console.log("USER");
        console.log(response.data);
        self.showHbdDialog(response.data);
      })
      .catch(function (error) {
        console.log(error.message);
        if (error.message.includes("401") || error.message.includes("403")) {
          self.props.history.push("/signin");
        }
      });
  }

  showHbdDialog(user) {
    if (user === null || user.birthDate === null) {
      return;
    }

    let birthDate = moment(user.birthDate, "DD.MM.YYYY");
    let today = moment(new Date());

    if (
      today.date() === birthDate.date() &&
      today.month() === birthDate.month() &&
      localStorage.getItem("hbdSeen") !== "yes"
    ) {
      this.setState({
        hbdDialogVisible: true,
      });
    }
  }

  handleMessageFromIframe = (e) => {
    if (e.data === "refresh me") {
      console.log(e);
      window.location.reload();
    }
  };

  componentDidMount() {
    let self = this;
    window.addEventListener("message", this.messageReceiver);

    // if no token in localStorage get the fuck out!
    if (localStorage.getItem("token") === null) {
      this.props.history.push("/signin");
      return;
    }

    // if token expired get the fuck out!
    if (
      moment(new Date(parseJwt(this.state.token).exp * 1000)).isBefore(moment())
    ) {
      this.props.history.push("/signin");
      return;
    }

    // Add event listener offline to detect network loss.
    window.addEventListener("offline", function (e) {
      self.setState({ isOnline: false, isNoInternetSnackbarOpen: true });
    });

    // Add event listener online to detect network recovery.
    window.addEventListener("online", function (e) {
      self.setState({ isOnline: true, isInternetSnackbarOpen: true });
    });

    this.getUser(localStorage.getItem("token"));

    this.getUnreadMessagesCount();

    if (messaging === null) {
      return;
    }

    // Get Instance ID token. Initially this makes a network call, once retrieved
    // subsequent calls to getToken will return from cache.
    messaging
      .getToken()
      .then((currentToken) => {
        console.log(currentToken);
        if (currentToken === null) {
          if (localStorage.getItem("blockNotifications") !== "1") {
            this.setState({ isNotificationSnackbarOpen: true });
          }
        }
        localStorage.setItem("fcm_token", currentToken);
        subscribeToNotifications(self.state.token, currentToken)
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
      });

    messaging.onTokenRefresh(() => {
      messaging
        .getToken()
        .then((refreshedToken) => {
          console.log("Token refreshed.");
          subscribeToNotifications(self.state.token, refreshedToken)
            .then((res) => {
              console.log(res);
              localStorage.setItem("fcm_token", refreshedToken);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log("Unable to retrieve refreshed token ", err);
        });
    });
  }

  updateTitle(title) {
    let prefix = "";
    if (window.location.hostname.includes("test")) {
      prefix = "TEST ";
      this.setState({ isTest: true });
    } else if (window.location.hostname.includes("dev")) {
      prefix = "DEV ";
      this.setState({ isDev: true });
    }

    this.setState({ documentTitle: prefix + title });
    document.title = prefix + title;
    this.checkPasswordChange();
  }

  checkPasswordChange(e) {
    if (!localStorage.getItem("token")) {
      return;
    }

    if (Date.now() >= localStorage.getItem("token").exp * 1000) {
      return;
    }

    let self = this;
    axios
      .get(
        AUTH_API_URL +
          "/users/lastPasswordChange/" +
          parseJwt(localStorage.getItem("token")).sub,
        {
          headers: {
            Authorization: `Bearer ${this.state.token}`,
          },
        },
      )
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error.message);
        if (error.message.includes("400")) {
          alert("Необходимо обновить пароль!");
          self.props.history.push("/profile", {
            changePassword: true,
          });
        }
      });
  }

  openDrawer() {
    this.setState({ isDrawerOpen: true });
  }

  openProfileMenu = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  navProfile() {
    this.setState({ anchorEl: null });
    this.props.history.push("/profile");
  }

  signOut() {
    this.setState({ anchorEl: null });
    localStorage.removeItem("token");
    localStorage.removeItem("blockNotifications");
    this.props.history.push("/signin");
    localStorage.removeItem("hbdSeen");
  }

  messageReceiver = (e) => {
    if (
      (e.origin.includes("localhost") || e.origin.includes("shinhanx.kz")) &&
      !e.data.payload &&
      !e.data.source
    ) {
      console.log(e.data);
      // if (e.data.includes("logout")) {
      //   localStorage.removeItem("token");
      //   this.props.history.push("/signin");
      // } else if (e.data.includes("token$")) {
      //   localStorage.setItem("token", e.data.split("$")[1]);
      // }
    }
  };

  openNotifications() {
    this.props.history.push("/messages");
  }

  getUnreadMessagesCount() {
    let self = this;
    axios
      .get(MISC_API_URL + "/message/getUnreadMessagesCount", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then(function (response) {
        console.log(response.data);
        self.setState({
          unreadMessagesCount: response.data,
        });
      })
      .catch(function (error) {
        console.log(error.message);
      });
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        {/*<Utp />*/}
        <CssBaseline />
        <AppBar
          position="fixed"
          style={
            this.state.isOnline
              ? this.state.isTest
                ? { backgroundColor: "#15a40f" }
                : this.state.isDev
                  ? { backgroundColor: "#0029ce" }
                  : { backgroundColor: "#333" }
              : { backgroundColor: "#ff0540" }
          }
          className={clsx(classes.appBar, {
            [classes.appBarShift]: this.state.isDrawerOpen,
          })}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => this.setState({ isDrawerOpen: true })}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: this.state.isDrawerOpen,
              })}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" style={{ flexGrow: "1" }}>
              {this.state.documentTitle}
            </Typography>
            <div>
              <IconButton
                aria-label="notifications"
                aria-controls="menu-appbar"
                onClick={this.openNotifications.bind(this)}
                color="inherit"
              >
                {
                  <Badge
                    badgeContent={this.state.unreadMessagesCount}
                    color="secondary"
                  >
                    <NotificationsActiveIcon color="#FF4545" />
                  </Badge>
                }
              </IconButton>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={this.openProfileMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={this.state.anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(this.state.anchorEl)}
                onClose={() => this.setState({ anchorEl: null })}
              >
                <MenuItem onClick={this.navProfile.bind(this)}>
                  Профиль
                </MenuItem>
                <MenuItem onClick={this.signOut.bind(this)}>Выход</MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: this.state.isDrawerOpen,
            [classes.drawerClose]: !this.state.isDrawerOpen,
          })}
          classes={{
            paper: clsx("scrolls", {
              [classes.drawerOpen]: this.state.isDrawerOpen,
              [classes.drawerClose]: !this.state.isDrawerOpen,
            }),
          }}
          open={this.state.isDrawerOpen}
        >
          <div className={classes.toolbar}>
            <object
              style={{
                marginTop: "5px",
                marginBottom: "5px",
              }}
              type="image/svg+xml"
              data={logoSvg}
              aria-label="logo"
              height="35"
              border="0"
            />
            <IconButton onClick={() => this.setState({ isDrawerOpen: false })}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          {this.state.user && (
            <UserMenu
              user={this.state.user}
              hideDrawer={this.hideDrawer}
              openDrawer={this.openDrawer.bind(this)}
              isDrawerOpen={this.state.isDrawerOpen}
            />
          )}
        </Drawer>

        <main className={classes.content}>
          <Switch>
            <Route
              exact
              path="/"
              component={(props) => <Home {...props} user={this.state.user} />}
            />
            <Route
              exact
              path="/messages"
              component={(props) => (
                <Messages
                  {...props}
                  user={this.state.user}
                  getUnreadMessagesCount={this.getUnreadMessagesCount.bind(
                    this,
                  )}
                />
              )}
            />

            <Route exact path="/collection">
              <ModuleContainer
                moduleURL={getModuleUrl("collection")}
                ref={this.collectionFrameRef}
                id="collection"
                token={this.state.token}
                updateTitle={() => this.updateTitle("Collection")}
              />
            </Route>
            <Route exact path="/carfin">
              <ModuleContainer
                moduleURL={getModuleUrl("carfin")}
                ref={this.finbrokerFrameRef}
                id="carfin"
                token={this.state.token}
                updateTitle={() => this.updateTitle("CarFin")}
              />
            </Route>
            <Route exact path="/welcome">
              <ModuleContainer
                moduleURL={getModuleUrl("welcome")}
                ref={this.welcomeFrameRef}
                id="welcome"
                token={this.state.token}
                updateTitle={() => this.updateTitle("Цифровой путеводитель")}
              />
            </Route>
            <Route exact path="/user_admin">
              <ModuleContainer
                moduleURL={getModuleUrl("auth-new")}
                user-admin
                ref={this.authFrameRef}
                id="user_admin"
                token={this.state.token}
                updateTitle={() => this.updateTitle("Админка")}
              />
            </Route>
            <Route exact path="/calendar">
              <ModuleContainer
                moduleURL={getModuleUrl("calendar")}
                ref={this.calFrameRef}
                id="calendar"
                token={this.state.token}
                updateTitle={() => this.updateTitle("Календарь")}
              />
            </Route>
            <Route exact path="/docs">
              <ModuleContainer
                moduleURL={getModuleUrl("docs")}
                ref={this.docsFrameRef}
                id="docs"
                token={this.state.token}
                updateTitle={() => this.updateTitle("Инструкции")}
              />
            </Route>
            <Route exact path="/sign-docs">
              <ModuleContainer
                moduleURL={getModuleUrl("sign-docs")}
                ref={this.signDocsFrameRef}
                id="sign-docs"
                token={this.state.token}
                updateTitle={() => this.updateTitle("eDOC")}
              />
            </Route>
            <Route exact path="/wiki">
              <ModuleContainer
                moduleURL={getModuleUrl("wiki")}
                ref={this.wikiFrameRef}
                id="wiki"
                token={this.state.token}
                updateTitle={() => this.updateTitle("Wiki")}
              />
            </Route>

            <Route exact path="/support">
              <ModuleContainer
                moduleURL={getModuleUrl("support-ax")}
                ref={this.supportFrameRef}
                id="support"
                token={this.state.token}
                updateTitle={() => this.updateTitle("Поддержка SHF")}
              />
            </Route>
            <Route exact path="/support_v2">
              <ModuleContainer
                moduleURL={getModuleUrl("renewed-support")}
                ref={this.renewedSupportFrameRef}
                id="support_v2"
                token={this.state.token}
                updateTitle={() => this.updateTitle("Help Desk")}
              />
            </Route>

            <Route exact path="/news">
              <ModuleContainer
                moduleURL={getModuleUrl("news")}
                ref={this.newsFrameRef}
                id="news"
                token={this.state.token}
                updateTitle={() => this.updateTitle("News")}
              />
            </Route>

            <Route exact path="/credit-flow">
              <ModuleContainer
                moduleURL={getModuleUrl("credit-flow")}
                ref={this.creditFlowFrameRef}
                id="news"
                token={this.state.token}
                updateTitle={() => this.updateTitle("Кредитный конвейер")}
              />
            </Route>

            <Route exact path="/staff-list">
              <ModuleContainer
                moduleURL={getModuleUrl("staff-list")}
                ref={this.staffListFrameRef}
                id="staff-list"
                token={this.state.token}
                updateTitle={() => this.updateTitle("Сотрудники")}
              />
            </Route>

            <Route
              exact
              path="/bi"
              render={(props) => (
                <ModuleContainer
                  {...props}
                  moduleURL={
                    props.location.state ? props.location.state.url : null
                  }
                  ref={this.xxxBIRef}
                  id="bi"
                  token={this.state.token}
                  updateTitle={() => this.updateTitle("BI")}
                />
              )}
            />

            <Route exact path="/profile" component={Profile} />
            <Route exact path="/profile/calendar" component={CalendarPage} />
          </Switch>
        </main>

        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={this.state.isNotificationSnackbarOpen}
          autoHideDuration={60000}
          onClose={() => this.setState({ isNotificationSnackbarOpen: false })}
          ContentProps={{ "aria-describedby": "message-id" }}
          message={<span id="message-id">Хотите получать уведомления?</span>}
          action={[
            <Button
              key="ok"
              color="primary"
              size="small"
              onClick={this.subscribeToNotifications.bind(this)}
            >
              Да
            </Button>,
            <Button
              key="no"
              color="secondary"
              size="small"
              onClick={() => {
                localStorage.setItem("blockNotifications", "1");
                this.setState({ isNotificationSnackbarOpen: false });
              }}
            >
              Нет
            </Button>,
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              className={classes.close}
              onClick={() =>
                this.setState({ isNotificationSnackbarOpen: false })
              }
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />

        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={this.state.isNoInternetSnackbarOpen}
          autoHideDuration={3000}
          onClose={() => this.setState({ isNoInternetSnackbarOpen: false })}
          ContentProps={{
            "aria-describedby": "message-id",
            style: { backgroundColor: "red" },
          }}
          message={<span id="message-id">Связь с Интернет прервана :(</span>}
          action={[]}
        />

        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={this.state.isInternetSnackbarOpen}
          autoHideDuration={3000}
          onClose={() => this.setState({ isInternetSnackbarOpen: false })}
          ContentProps={{
            "aria-describedby": "message-id",
            style: { backgroundColor: "green" },
          }}
          message={
            <span id="message-id">Связь с Интернет восстановлена! :)</span>
          }
          action={[]}
        />

        <Dialog
          open={this.state.hbdDialogVisible}
          onClose={() => this.setState({ hbdDialogVisible: false })}
        >
          <DialogTitle>С днем рождения!</DialogTitle>
          <DialogContent>
            <div>
              Дорогой {this.state.user && this.state.user.firstName}! 😊
              <p>Поздравляем тебя с днем рождения! 🥳🎂🎉 </p>Пусть сбываются
              твои мечты, все вокруг вдохновляет и приносит самые яркие эмоции!
              🤩 Желаем приятных путешествий, самых лучших людей на твоем пути и
              удовольствия от всего, что ты делаешь! 🎊 А shinhanx пусть
              помогает тебе добиваться высоких показателей на работе! 🚀
              <p>C уважением, команда Shinhan Finance! ❤️</p>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.setState({
                  hbdDialogVisible: false,
                });
                localStorage.setItem("hbdSeen", "yes");
              }}
            >
              Спасибо!
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(App);
